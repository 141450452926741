<template>
  <div v-bind:style="styles" class="pgwrapper" id="pgwrapper">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex">
       
       <!-- <div class="backbox">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Dashboard
       </h4>
     </div>
      <!-- <div class="row">
        <div class="col-lg-6 mt-4">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: 90%">
                <span class="iconsect orgbg" style="padding-left: 6px"
                  ><i class="fas fa-school"></i
                ></span>
                <h1>Total Seats And Seats Filled </h1>
              </div>
            </div>
            <div class="widjetcontent" v-if="allowBarForBoysAndGirls">
              <div class="mt-4 mr-2">
                <apexchart
                  type="bar"
                  height="420"
                  :options="seatsOption"
                  :series="seatsSeries"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: 90%">
                <span class="iconsect blubg" style="padding-left: 14px"
                  ><i class="fas fa-rupee-sign"></i
                ></span>
                <h1>No of Leads</h1>
              </div>
            </div>
            <div class="widjetcontent" v-if="allowBar">
              <div class="mt-4 mr-2">
                <apexchart
                  height="420"
                  type="bar"
                  :options="admissionOptions"
                  :series="admissionSeries"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: 90%">
                <span class="iconsect orgbg" style="padding-left: 6px"
                  ><i class="fas fa-school"></i
                ></span>
                <h1>NO OF BOYS/GIRLS </h1>
              </div>
            </div>
            <div class="widjetcontent" v-if="allowBarForBoysAndGirls">
              <div class="mt-4 mr-2">
                <apexchart
                  type="bar"
                  height="420"
                  :options="studentCountOptions"
                  :series="studentCountSeries"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        
      </div> -->
      <div class="row p-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <!-- <div class="backbox">
                <div
                  class="back-arrow"
                  @click="previousRoute"
                  style="margin-top: 5px"
                >
                  <i
                    class="fa fa-arrow-left"
                    style="padding-top: 11px; padding-left: 11px"
                  ></i>
                </div>
              </div> -->
              <div style="width: auto; margin-left: -42%">
                <!-- <h1>Dashboard</h1> -->
              </div>

              <div style="width: 6%"></div>
              <div style="width: 14%; margin-left: 0%">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    @change="getSelectRange"
                    v-model="selectedAcademicYear"
                  >
                    <option :value="null">-- Select Academic Year --</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                  <label for="acyearid"
                    >Academic Year <span class="required">*</span></label
                  >
                </span>
                <!-- <span class="has-float-label" style="margin-top: 4%">
                  <select
                   @change="checkValueChanged"
                    class="form-control form-input"
                    id="deptlistid"
                    :disabled="!selectedClass"
                    v-model="selectedSection"
                  >
                    <option :value="null">-- Select --</option>

                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="clslistid">Select Classroom </label>
                </span> -->
              </div>

              <div class="mt-1"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-6 col-lg-6">
          <div class="white-box org mb-2">
            <div class="no-org no-cirlce">
              {{ schoolOverallCounts.student }}
            </div>
            <p class="widgettitle">Total<span>Active Students</span></p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="white-box blu mb-2">
            <div class="no-blu no-cirlce">
              {{ schoolOverallCounts.admissionCount }}
            </div>
            <p class="widgettitle">Total<span>No. of Leads</span></p>
          </div>
        </div>
      </div>
      <h4 class="mb-0 mt-3 ml-1" style="font-weight: bold"></h4>
      <div class="row mt-3">
        <div class="col-sm-6 col-lg-3">
          <div class="white-box org mb-2">
            <!-- <div class="no-org no-cirlce">100%</div> -->
            <p class="widgettitle">
              Total Fee Collected<span
                >$ {{ schoolCounts.totalSumAmount }}</span
              >
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="white-box blu mb-2">
            <!-- <div class="no-blu no-cirlce">50%</div> -->
            <p class="widgettitle">
              Discount Applied<span
                >$ {{ schoolCounts.getDiscountAmount }}</span
              >
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="white-box org mb-2">
            <!-- <div class="no-org no-cirlce">4.9%</div> -->
            <p class="widgettitle">
              Penalty Received<span
                >$ {{ schoolCounts.getPenaltyAmountOnly }}</span
              >
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="white-box blu mb-2">
            <!-- <div class="no-blu no-cirlce">15%</div> -->
            <p class="widgettitle">
              Grand Total Amount<span
                >$ {{ schoolCounts.grandTotalAmount }}</span
              >
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="white-box blu mb-2">
            <!-- <div class="no-blu no-cirlce">15%</div> -->
            <p class="widgettitle">
              Fee Forecast [Fee Plan Only]<span
                >$ {{ schoolCounts.foreCastAmount }}</span
              >
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="white-box blu mb-2">
            <!-- <div class="no-blu no-cirlce">15%</div> -->
            <p class="widgettitle">
              Overdue<span>$ {{ schoolCounts.dueAmount }}</span>
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="white-box blu mb-2">
            <!-- <div class="no-blu no-cirlce">15%</div> -->
            <p class="widgettitle">
              Outstanding Amount<span>$ {{ balanceAmount }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "Dashboard",

  data() {
    return {
      viLoader: false,
      admissionSeries: [
        {
          name: "Total",
          data: [],
        },
        {
          name: "Active",
          data: [],
        },
        {
          name: "Reject",
          data: [],
        },
      ],
      admissionOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Total Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      studentCountSeries: [
        {
          name: "Boys",
          data: [],
        },
        {
          name: "Girls",
          data: [],
        },
      ],
      studentCountOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Total Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seatsSeries: [
        {
          name: "No Of Seats",
          data: [],
        },

        {
          name: "Seats Filled",
          data: [],
        },
      ],
      seatsOption: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
      selectedAcademicYear: null,
      schoolCounts: {
        totalSumAmount: 0,
        getDiscountAmount: 0,
        getPenaltyAmountOnly: 0,
        grandTotalAmount: 0,
        foreCastAmount: 0,
        dueAmount: 0,
      },
      schoolOverallCounts: {
        admissionCount: 0,
        student: 0,
      },
      academicYearList: [],
      allowBar: false,
      allowBarForBoysAndGirls: false,
    };
  },

  created() {
    // this.getStudentCountDashboardData();
    // this.getAdmissionDashboardData();
    this.getSchoolDetailDashboardData();
    this.getSchoolOverallDetailDashboardData("new");
    // this.getNoOfSeatsAdmissionDashboardData()
    this.getOverdueAndForeCastAmount();
    this.getAcademicYearList();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    balanceAmount() {
      return (
        this.schoolCounts.foreCastAmount - this.schoolCounts.totalSumAmount
      );
    },
  },

  methods: {
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          if (this.academicYearList.length > 0) {
            let getDetails = this.$store.getters.getCurrentAcademicYear;

            if (getDetails && getDetails.from) {
              // @change="getSelectRange"
              this.selectedAcademicYear = getDetails._id;
            }
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getStudentCountDashboardData() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/dashboard/all`,
          userData.token
        );
        if (response.isSuccess) {
          let result = response.data;
          if (result && result != null && result != undefined) {
            Object.keys(result).forEach((x) => {
              this.studentCountOptions.xaxis.categories.push(x);
              if (result[x]["Male"]) {
                this.studentCountSeries[0].data.push(result[x].Male.length);
              }
              if (result[x]["Female"]) {
                this.studentCountSeries[1].data.push(result[x].Female.length);
              }
            });
          }
          this.allowBarForBoysAndGirls = true;
        }
      }
    },
    async getNoOfSeatsAdmissionDashboardData() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/dashboard/noOfSeats/admission`,
          userData.token
        );
        if (response.isSuccess) {
          let result = response.data;

          if (result && result != null && result != undefined) {
            Object.keys(result).forEach((x) => {
              this.seatsOption.xaxis.categories.push(x);
              if (result[x][0]["noOfSeats"]) {
                this.seatsSeries[0].data.push(result[x][0].noOfSeats);
              }
              if (result[x][0]["seatsFilled"]) {
                this.seatsSeries[1].data.push(result[x][0].seatsFilled);
              }
            });
          }
        }
      }
    },
    async getAdmissionDashboardData() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/dashboard/admission`,
          userData.token
        );
        if (response.isSuccess) {
          let result = response.data.admissionList;

          if (result) {
            Object.keys(result).forEach((x) => {
              this.admissionOptions.xaxis.categories.push(x);
              if (result[x]["Active"]) {
                this.admissionSeries[0].data.push(result[x].Active.length);
              }
              if (result[x]["Approved"]) {
                this.admissionSeries[1].data.push(result[x].Approved.length);
              }
              if (result[x]["Submitted"]) {
                this.admissionSeries[2].data.push(result[x].Submitted.length);
              }
            });
          }
          this.allowBar = true;
        }
      }
    },
    getSelectRange() {
      if (this.selectedAcademicYear) {
        this.getSchoolDetailDashboardData();
        this.getSchoolOverallDetailDashboardData("old");
        this.getOverdueAndForeCastAmount();
      } else {
        this.selectedAcademicYear = null;
        this.getSchoolDetailDashboardData();
        this.getSchoolOverallDetailDashboardData("old");
        this.getOverdueAndForeCastAmount();
      }
    },
    async getSchoolDetailDashboardData() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/dashboard/school/details?academicYear=${this.selectedAcademicYear}`,
          userData.token
        );

        if (response.isSuccess) {
          let result = response.data;

          if (result) {
            this.schoolCounts.totalSumAmount = result.totalSumAmount || 0;
            this.schoolCounts.getDiscountAmount = result.getDiscountAmount || 0;
            this.schoolCounts.getPenaltyAmountOnly =
              result.getPenaltyAmountOnly || 0;
            this.schoolCounts.grandTotalAmount = result.grandTotalAmount || 0;
          }
        }
      }
    },

    async getSchoolOverallDetailDashboardData(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (type == "new") {
          this.viLoader = true;
        }
        // this.viLoader = true
        const response = await ViService.viXGet(
          `/dashboard/school/overall?academicYear=${this.selectedAcademicYear}`,
          userData.token
        );
        if (response.isSuccess) {
          let result = response.data;
          if (result) {
            this.schoolOverallCounts.admissionCount =
              result.admissionCount || 0;
            this.schoolOverallCounts.student = result.studentsCount || 0;
          }
        }
        this.viLoader = false;
      }
    },
    async getOverdueAndForeCastAmount() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/dashboard/overdueAndForeCastAmount?academicYear=${this.selectedAcademicYear}`,
          userData.token
        );
        if (response.isSuccess) {
          let result = response.data;
          if (result) {
            this.schoolCounts.foreCastAmount = result.foreCast || 0;
            this.schoolCounts.dueAmount = result.overDue || 0;
          }
        }
      }
    },
  },
  components: {
    ViSpinner,
  },
};
</script>
